<template>
    <div>
        <div   class=" pb-8 diagonal pt-0 pt-md-5">
            <div class="py-4 mt--4 mb--3">
                <h3 class="mb-0 text-white pt-4">Správa modulu</h3>
                <h2 class="" v-if="data.title">{{data.title}}</h2>
                <h2 class="" v-else>...</h2>
                <div class="text-right mt--5" style="float:right">
                    <router-link to="/modules" v-if="this.id" class="nav-link" href="/modules">
                        <template>
                            <a-button type="primary" ><i class="fad fa-th-list pr-1"></i> Moduly</a-button>
                        </template>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="container-fluid mt--7" v-if="data.info">
            <div class="row no-gutters">

        <div class="col-9">
            <div class="row">
<div v-if="data.info.var === '1'" class="col-12   order-xl-1">
  <a-card shadow type="secondary"  >
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Demo obsah</h3>
                                </div>
                                <div class="col-4 text-right">
                                </div>
                                </div>
                        </div>
         <div class="row align-items-center">
                                <div class="col-8">
                                   <p>Pre tento modul je možné nainštalovať demo obsah. </p>
                                </div>
                                <div class="col-4 text-right">
                                     <button class="btn btn-primary" @click="installDemo">Pridať obsah</button>
                                </div>
                                </div>
</a-card>
</div>


 <div class="col-xl-4 order-xl-1 mt-3" v-if="data.templates.i || data.templates.n">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Šablóny</h3>
                                </div>
                                <div class="col-4 text-right">
                                </div>
                            </div>
                        </div>
<div>
    <div v-if="data.templates.i">
<h4   style="color:green;">Pre tento modul sú nainštalované tieto šablóny</h4>
<ul>
<li v-for="ctype in data.templates.i" :key="ctype.id" style="list-style:none" class="w-100 ctype-allowd">
 <a-icon type="check"  :style="{color: 'green', paddingRight: '10px', fontSize: '13px',position: 'relative',top: '-4px'}" />
 <span> {{ctype.setup.title}}</span>
</li>
</ul>
</div>
<span v-if="data.templates.n">
<h4 style="color:red;"  >Pre tento modul sú potrebné tieto šablóny</h4>
<ul>
<li v-for="ctype in data.templates.n" :key="ctype.id" style="list-style:none" class="w-100 ctype-allowd">
<a-icon type="close"  :style="{color: 'red', paddingRight: '10px', fontSize: '13px',position: 'relative',top: '-4px'}" />
<span>{{ctype.setup.title}}</span>
</li>
</ul>
</span>
</div>

   </a-card>
</div>


 <div class="col-xl-4 order-xl-1 mt-3" v-if="data.mh || data.mn">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Menu</h3>
                                </div>
                                <div class="col-4 text-right">
                                </div>
                            </div>
                        </div>
<div>
    <div v-if="data.mh">
<h4   style="color:green;">Pre tento modul sú nainštalované tieto menu</h4>
<ul>
<li v-for="ctype in data.mh" :key="ctype.slug" style="list-style:none" class="w-100 ctype-allowd">
 <a-icon type="check"  :style="{color: 'green', paddingRight: '10px', fontSize: '13px',position: 'relative',top: '-4px'}" />
 <span> {{ctype.title}}</span>
</li>
</ul>
</div>
<span v-if="data.mn">
<h4 style="color:red;">Pre tento modul sú potrebné tieto menu</h4>
<ul>
<li v-for="ctype in data.mn" :key="ctype.slug" style="list-style:none" class="w-100 ctype-allowd">
<a-icon type="close"  :style="{color: 'red', paddingRight: '10px', fontSize: '13px',position: 'relative',top: '-4px'}" />
<span>{{ctype.title}}</span>
</li>
</ul>
</span>
</div>

   </a-card>
</div>




                <div class="col-xl-4 order-xl-1 mt-3" v-if="data.canadd || data.existing">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Typy obsahov</h3>
                                </div>
                                <div class="col-4 text-right">
                                </div>
                            </div>
                        </div>

<div v-if="data.canadd" style="margin-bottom:30px;">
    <h4 >Je možné nainštalovať nasledujúce typy obsahov</h4>
 <a-collapse v-if="data.canadd" v-model="activeKey" expand-icon-position="left">
      <a-collapse-panel :key="'ctype' + ctype.id" v-for="ctype in data.canadd" :header="ctype.title">
      <a-icon slot="extra" type="check" :style="{color: 'green',fontSize: '13px',position: 'relative',top: '-4px'}" />
       <b style="font-size:11px; margin-bottom:10px; display:inline-block;">Polia pre daný typ obsahu ({{ctype.slug}})</b>
      <ul class="w-100" style="font-size:11px;"><li v-for="field in ctype.fields" :key="field.id">
{{field.title}} - <span style="font-size:10px;color:#bbb">{{types[field.type-1].label}}</span>
<span v-if="field.type === '8'"> {{data.types[ctype.context[field.id]]}}</span>
<span v-if="field.type === '12'"> {{data.taxo[ctype.context[field.id]]}}</span>

<span v-if="field.type === '17'"> <ul> <li :key="option.id" v-for="option in field.fields[0]"> {{option.title}} </li> </ul></span>
<span v-if="field.type === '2'"> <ul> <li :key="option.value" v-for="option in ctype.options[field.id]"> {{option.label}} </li> </ul></span>
</li></ul>
      </a-collapse-panel>
    </a-collapse>
    </div>
<div v-if="data.existing && data.info.var !== '1'">
<h4 style="color:red;">Je potrebné vyriešiť konflikt v nasledujúcich typoch obsahu</h4>
<ul>
<li v-for="ctype in data.existing" :key="ctype.id" style="list-style:none" class="w-100 ctype-allowd"><a-icon type="close"  :style="{color: 'red',fontSize: '13px',position: 'relative',top: '-4px'}" /> {{ctype.title}} -
<router-link :to="'/content-type/' + ctype.conflict" :href="'/content-type/' + ctype.conflict">
                        <template>
<span style="color:red"> {{ctype.slug}} <a-icon type="login"  :style="{fontSize: '13px',position: 'relative',top: '-4px'}" /> </span>
                        </template>
                    </router-link>
</li>
</ul>
</div>
<div  v-if="data.info.var == 1 && data.existing">
<h4 style="color:green;">Boli nainštalované typy obsahov</h4>
<ul>
<li v-for="ctype in data.existing" :key="ctype.id" style="list-style:none" class="w-100 ctype-allowd">
    <a-icon v-if="ctype.missingFields" type="exclamation-circle"  :style="{color: 'red', paddingRight: '10px', fontSize: '13px',position: 'relative',top: '-4px'}" />
    <a-icon v-else type="check" :style="{color: 'green', paddingRight: '10px', fontSize: '13px',position: 'relative',top: '-4px'}" />
   <span >
    <router-link :style="ctype.missingFields ? 'color:red' : 'color:green'" :to="'/content-type/' + ctype.conflict" :href="'/content-type/' + ctype.conflict">
    <template >{{ctype.title}} <a-icon type="login" :style="{fontSize: '13px',position: 'relative',top: '-4px'}" /></template>
    </router-link>
</span>
<div class="missingfields" v-if="ctype.missingFields">
<ul style="padding:0;padding-left:23px; font-size:12px;">
    <li title="Chýbajúce pole v type obsahu" :key="field.id" v-for="field in ctype.missingFields" style="list-style:none">
    {{field.title}}  - <span style="font-size:10px;color:#bbb">{{types[field.type-1].label}}

<span v-if="field.type === '8'"> {{data.types[ctype.context[field.id]]}}</span>
<span v-if="field.type === '12'"> {{data.taxo[ctype.context[field.id]]}}</span>
<span v-if="field.type === '17'"> <ul> <li :key="option.id" v-for="option in field.fields[0]"> {{option.title}} </li> </ul></span>
<span v-if="field.type === '2'"> <ul> <li :key="option.value" v-for="option in ctype.options[field.id]"> {{option.label}} </li> </ul></span>
</span>
 </li>
</ul>

    </div>
</li>
</ul>
</div>
</a-card>
</div>



 <div class="col-xl-4 order-xl-1 mt-3" v-if="data.permn || data.perma">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Právomoci</h3>
                                </div>
                                <div class="col-4 text-right">
                                </div>
                            </div>
                        </div>
<div>
<div v-if="data.permn" :style="data.perma ? 'margin-top:20px;' : ''">
 <h4 style="color:red">Nasledujúce právomoci budú priradené rolám: </h4>
 <a-select class="w-100" mode="multiple" :options="data.roles" v-model="activeRoles"></a-select>
<ul style="">
<li v-for="perm in data.permn" :key="perm"  style="list-style:none">
    <a-icon type="exclamation-circle"  :style="{color: 'red', paddingLeft: '5px',paddingRight: '5px', fontSize: '13px',position: 'relative',top: '-4px'}" /> {{perm}} </li>
</ul>
</div>
<div v-if="data.perma">
 <h4 style="color:green">Tieto právomoci boli priradené všetkým adminom. </h4>
<ul style="padding:0">
<li v-for="perm in data.perma" style="display:inline" :key="perm" class="w-100 ctype-allowd">
<a-icon slot="extra" type="check" :style="{color: 'green',fontSize: '13px',position: 'relative',top: '-4px'}" /> {{perm}} </li>
</ul>
</div>
</div>
</a-card>
</div>

    <div class="col-xl-4 order-xl-1 mt-3" v-if="data.taxcanadd || data.taxexisting">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Taxonomické slovníky</h3>
                                </div>
                                <div class="col-4 text-right">
                                </div>
                            </div>
                        </div>

<h4 v-if="data.info.var !== '1'">Je možné nainštalovať nasledujúce taxonómie</h4>
<a-collapse v-if="data.info.var !== '1'" v-model="activeKey" expand-icon-position="left">
<a-collapse-panel :key="ctype.id" v-for="ctype in data.taxcanadd" :header="ctype.title">
<a-icon slot="extra" type="check" :style="{color: 'green',fontSize: '13px',position: 'relative',top: '-4px'}" />
<b style="font-size:11px; margin-bottom:10px; display:inline-block;">Polia pre daný slovník</b>
<ul class="w-100" style="font-size:11px;"><li v-for="field in ctype.fields" :key="field.id">
{{field.title}} - <span style="font-size:10px;color:#bbb">{{types[field.type-1].label}}</span> <span v-if="field.type === '8'"> {{data.types[ctype.context[field.id]]}}</span>
</li></ul>
      </a-collapse-panel>
    </a-collapse>

<div v-if="data.taxexisting && data.info.var !== '1'">
<h4 style="color:red; margin-top:30px;">Je možný konflikt v taxonómiach</h4>
<ul>
<li v-for="ctype in data.taxexisting" :key="ctype.id" style="list-style:none" class="w-100 ctype-allowd">
<a-icon type="exclamation-circle" v-if="ctype.ifExists === true" :style="{color: 'red',fontSize: '13px',position: 'relative',top: '-4px'}" />
    <a-icon type="close" v-else  :style="{color: 'red',fontSize: '13px',position: 'relative',top: '-4px'}" /> {{ctype.title}} -
<router-link :to="'/taxonomy/' + ctype.conflict" :href="'/taxonomy/' + ctype.conflict">
                        <template>
<span style="color:red"> {{ctype.slug}} <a-icon type="login"  :style="{fontSize: '13px',position: 'relative',top: '-4px'}" /> </span>

                        </template>
                    </router-link>
<span v-if="ctype.ifExists === true">
    <span class="fs-10 w-100 text-muted" style="display:inline-block; font-weight:bold;">Do existujúceho slovníka sa nainštalujú tieto termíny</span>
    <ul>
<li v-for="datas in ctype.data" :key="datas.id" style="list-style:none" class="w-100 ctype-allowd">
    <a-icon type="plus"  :style="{color: 'green', paddingRight: '10px', fontSize: '13px',position: 'relative',top: '-4px'}" />
    {{datas.title}}
    </li>
    </ul>
</span>

</li>
</ul>
</div>
<div  v-if="data.info.var == 1">
<h4 style="color:green;">Boli nainštalované taxonomické slovníky</h4>
<ul>
<li v-for="ctype in data.taxexisting" :key="ctype.id" style="list-style:none" class="w-100 ctype-allowd">
    <a-icon type="check"  :style="{color: 'green', paddingRight: '10px', fontSize: '13px',position: 'relative',top: '-4px'}" />
<span style="color:green">
    <router-link style="color:#32325d" :to="'/taxonomy-items/' + ctype.conflict" :href="'/taxonomy-items/' + ctype.conflict">
    <template >{{ctype.title}} <a-icon type="login" :style="{fontSize: '13px',position: 'relative',top: '-4px'}" /></template>
    </router-link>
</span>
</li>
</ul>
</div>

<div  v-if="data.info.var == 1">
<h4 style="color:red;" v-if="data.taxcanadd">Pre tento modul je potrebné pridať nové taxonomické slovníky</h4>
<ul>
<li v-for="ctype in data.taxcanadd" :key="ctype.id" style="list-style:none" class="w-100 ctype-allowd">
    <a-icon type="close"  :style="{color: 'red', paddingRight: '10px', fontSize: '13px',position: 'relative',top: '-4px'}" />
<span style="color:green">
    <router-link style="color:#32325d" :to="'/taxonomy-items/' + ctype.conflict" :href="'/taxonomy-items/' + ctype.conflict">
    <template >{{ctype.title}} </template>
    </router-link>
</span>
</li>
</ul>
</div>

   </a-card>
</div>
    </div>
    </div>
    <div class="col-3">
         <div class="col-12 mb-3">
                    <a-card shadow type="secondary">
                        <div class="header bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Informácie</h3>
                                </div>
                                <div class="col-4 text-right">
                                </div>
                                </div>
                        </div>

        <div class="w-100"><label style="padding-right:10px">Slug:</label>{{data.id}}</div>
        <div class="w-100"><label style="padding-right:10px">Stav:</label> <span v-if="data.info.var" style="color:green"><span v-if="data.info.var === '1'">spustené</span> {{data.info.value}}</span><span style="color:red" v-else>neaktívny modul</span></div>


 <div class="text-right">
          <button v-if="data.info.var !== '1'" class="btn btn-primary" @click="manageModules" :disabled="data.taxexisting || data.existing">Nainštalovať</button>
 </div>
</a-card>
</div>

<div v-if="data.info.var === '1' && (data.taxcanadd || data.templates.n || data.canadd || data.mn)" class="col-12">
<a-card  class="bg-gradient-default  text-white"  >
<p>Pre tento modul existuje nová aktualizácia. </p>

                                <div class=" text-right">
                                     <button class="btn btn-primary" @click="updateModule">Aktualizovať</button>
                                </div>
</a-card>
</div>
    </div>
    </div>

</div>
</div>

    </div>
</template>
<script>
    import {API_URL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import ASelect from "ant-design-vue/es/select";
    import { TreeViewPlugin } from "@syncfusion/ej2-vue-navigations";
    import treeTemplateVueFields from "./content/tree-templateField.vue";
    Vue.use(TreeViewPlugin);
    import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";
    Vue.use(ContextMenuPlugin);
    Vue.use(FormItem);
    const columns = [
        {
            title: 'Názov menu',
            dataIndex: 'title',
            sorter: true,
            width: '20%',
        },
        {
            title: 'URL',
            dataIndex: 'url',
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];

    const columns2 = [
        {
            title: 'Name',
            dataIndex: 'title',
            sorter: true,
            width: '20%',
        },
        {
            title: 'Typ poľa',
            dataIndex: 'type',
            scopedSlots: {customRender: 'cttype'},
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];
    let id = 0;
    export default {
        name: 'contenttype',
        components: {'a-select': ASelect},
        props: ['id'],
        data() {
            return {
                data: [],
                colorsto: JSON.parse(localStorage.colors),
                treeTemplate: function() {
                    return {
                        template: treeTemplateVueFields
                    };
                },
                menuItems : [
                    { text: 'Upraviť' },
                    { text: 'Zmazať' },
                ],
                fields: {},
                 activeKey: ['1'],
                 activeRoles: ['1'],
                newField: {
                    title : null,
                    type : null,
                    blocks: [],
                    options : [{title: '', slug: ''}],
                    fieldcolection: {'title':[],'type':[],'setup':[]},
                    multi: false,
                    multibool: false,
                    independentbool: false,
                    translatablebool: false,
                    slug : null,
                    content_id : this.id
            },  groupField: {
                    groupID : null,
                    content_id : this.id
                },
                loading: false,
                ctnodes:{},
                fieldStoShow:{},
                allFields:{},
                addFieldModal:false,
                addGroupModal:false,
                columns,
                columns2,
                types: [
                    {"value" : '1' ,"label" : 'Text'},
                    {"value" : '2' ,"label" : 'Výberovník'},
                    {"value" : '3' ,"label" : 'Obrázok'},
                    {"value" : '4' ,"label" : 'Dlhý text'},
                    {"value" : '5' ,"label" : 'Celé číslo'},
                    {"value" : '6' ,"label" : 'Desatiné číslo'},
                    {"value" : '7' ,"label" : 'Logická hodnota Áno/Nie'},
                    {"value" : '8' ,"label" : 'Priradenie pod typ obsahu'},
                    {"value" : '9' ,"label" : 'Dynamická hodnota'},
                    {"value" : '10' ,"label" : 'Builder'},
                    {"value" : '11' ,"label" : 'Galéria obrázkov'},
                    {"value" : '12' ,"label" : 'Priradenie pod taxonómiu'},
                    {"value" : '13' ,"label" : 'Priradenie pod taxonómiu s násobkom'},
                    {"value" : '14' ,"label" : 'Dátum'},
                    {"value" : '15' ,"label" : 'Multipole - vlastnosti'},
                    {"value" : '16' ,"label" : 'Dátum od - do'},
                    {"value" : '17' ,"label" : 'Multipole'},
                    {"value" : '18' ,"label" : 'Ikona'},
                    {"value" : '19' ,"label" : 'Súbor'},
                    {"value" : '20' ,"label" : 'Skupina'},
                    {"value" : '21' ,"label" : 'Mapa'},
                ],
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 4 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 24 },
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: { span: 24, offset: 0 },
                        sm: { span: 20, offset: 4 },
                    },
                },
                head: authHeader(),
                send: API_URL,
                  contenttypeId: {
                    uid: this.id
                },
                contenttypes: [],
                productCat: {},
                grouping: {},
                taxs:{},
                contenttype: {
                    title: '',
                    type: '',
                    product : '',
                },
            }
        },
        watch: {
    activeKey(key) {
      console.log(key);
    },
  },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'dynamic_form_item' });
            this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
        },       computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        methods: {
            nodeclicked: function(args) {
                if (args.event.which === 3) {
                    var treeObj = document.getElementById('treeview').ej2_instances[0];
                    treeObj.selectedNodes = [args.node.getAttribute('data-uid')];
                }
            },menuclick: function(args) {
                var treevalidate = document.getElementById('treeview').ej2_instances[0];
                var targetNodeId = treevalidate.selectedNodes[0];
                if (args.item.text === "Upraviť") {
                this.openFieldModal(this.allFields[targetNodeId]);
                }
                else if (args.item.text === "Zmazať") {
                    this.confirm(targetNodeId);
                }
            },
            updateModule()
            {

                dataService.axiosPost('updatemodule', {'e': this.data.namespace,'r': this.activeRoles}).then(results => {
                    if (results) {
                    this.fetch();
                    }
                });
            },
              manageModules()
                {
                dataService.axiosPost('managemodules', {'e': this.data.namespace,'r': this.activeRoles}).then(results => {
                    if (results) {
                    this.fetch();
                    }
                });
                },
                installDemo()
                {
                dataService.axiosPost('democontent', {'d': this.data.namespace}).then(results => {
                    if (results) {
                    this.fetch();
                    }
                });
                },
                uninstallModules()
                {
                dataService.axiosPost('managemodules', {'d': this.data.namespace}).then(results => {
                    if (results) {
                    this.fetch();
                    }
                });
                },
            beforeopen: function() {
                var treevalidate = document.getElementById('treeview').ej2_instances[0];
                var targetNodeId = treevalidate.selectedNodes[0];
                var targetNode = document.querySelector('[data-uid="' + targetNodeId + '"]');
                var contentmenutree = document.getElementById('contentmenutree').ej2_instances[0];
                if (targetNode.classList.contains('remove')) {
                    contentmenutree.enableItems(['Zmazať'], false);
                }
                else {
                    contentmenutree.enableItems(['Zmazať'], true);
                }
            },
            nodeDrag(args) {

              //  console.log(args);
                if (args.droppedNode.getElementsByClassName('icon-20').length === 0 && args.droppedNode != null && args.dropIndicator === 'e-drop-in') {
                  args.dropIndicator = 'e-no-drop';
              }
            },
            dragStop(args) {

   if (args.droppedNode.getElementsByClassName('icon-20').length === 0 && args.dropIndicator === 'e-no-drop') {
                        args.cancel = true;
                    }

            },
            updateFields(e)
            {
                let sendata = e.data;
                dataService.axiosPost('reordergroupfields/' + this.id, sendata).then(() => {
                });
            },
            openFieldModal(id = false){
if(parseInt(id.id) > 0)
{
    this.newField = id;
}
else {
    this.newField = {title : null,type : null, blocks: [],options : [{title: '',slug: ''}], fieldcolection: {'title':[],'type':[],'setup':[]}, multi: false, slug : null,content_id : this.id };
}
              this.addFieldModal = true;
            },
            openGroupModal(id = false){
                if(parseInt(id.id) > 0)
                {
                    this.groupField.groupID = id;
                }
                else {
                    this.groupField = { groupID : null, content_id : this.id  };
                }
                this.addGroupModal = true;
            },
            confirm(e) {
                dataService.axiosDelete('contenttypefields', e).then(results => {
                    if (results) {
                     this.fetch();
                    }
                });
            },
            onChange(checked) {
                this.newField.multi = checked;
            },
            cancel() {
            },
            updateOrderFields()
            {
                dataService.axiosPost('reorderfields/' + this.id, this.contenttype.fields).then(() => {

                });
            },
            saveReorder()
            {
                dataService.axiosPost('reorderblocks/' + this.id, this.contenttype.blocks).then(() => {

                });
                    },
            handleChange() {

            },
            remove(k) {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                // We need at least one passenger
                if (keys.length === 1) {
                    return;
                }

                // can use data-binding to set
                form.setFieldsValue({
                    keys: keys.filter(key => key !== k),
                });
            },

            updateBlocks() {

            },
            add() {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                const nextKeys = keys.concat(id++);
                // can use data-binding to set
this.newField.options.push({ title: '', slug: ''});

                // important! notify form to detect changes
                form.setFieldsValue({
                    keys: nextKeys,
                });
            },
            handleSubmit() {
                this.contenttype.multi = this.contenttype.multibool;
                let $contenttype_data = this.contenttype;

                dataService.axiosPost('contenttype', $contenttype_data).then(results => {

                    if(results.data)
                    {

                        if (isNaN(this.id)) {  this.contenttype = {};this.$router.push('/content-type/'+results.data.message) }

                    }

                    this.addFieldModal = false;
                });
            },
            handleNewGroupSubmit()
            {
                this.addFieldModal = false;
                let $contenttype_data = this.groupField;
                dataService.axiosPost("contenttype/newGroup", $contenttype_data).then(results => {
                    if(results.data.data)
                    {
                     this.fetch();
                    }
                });
            },
            handleNewFieldSubmit()
            {  this.addFieldModal = false;
                this.newField.multi = this.newField.multibool;
                this.newField.independent = this.newField.independentbool;
                this.newField.translatable = this.newField.translatablebool;
                let $contenttype_data = this.newField;
                dataService.axiosPost("contenttype/newfield", $contenttype_data).then(results => {
                    if(results.data.data)
                    {
                        this.fetch();
                    }
                });
            },
            fetch()
            {
                dataService.axiosFetch('module', this.id).then(results => {
this.data = results;

                });
            }
        },
        mounted() {
         this.fetch();
        }
    };
</script>
<style scoped>
    @import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
    @import "../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
    @import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
    .control_wrapper >>> .e-treeview .e-text-content .e-list-text
    {width:100%;}

   .control_wrapper >>> .e-treeview:not(.e-fullrow-wrap) .e-list-item.e-hover > .e-text-content
    {box-shadow:0 0;
    border-color:transparent;}
    .control_wrapper >>> .e-treeview .e-text-content .e-list-text .treeviewdiv
    {
        width:100%;  padding-top:5px;border-top:1px solid #f1f1f1;
    }
    .control_wrapper >>> .e-treeview .e-text-content .e-list-text .treeviewdiv:hover
    {color:#000;}
    .control_wrapper >>> .e-treeview:not(.e-fullrow-wrap) .e-list-item.e-active > .e-text-content
    {box-shadow:0 0;
        border-color:transparent;}
    .control_wrapper >>> .e-treeview .e-list-item.e-active > .e-text-content .e-list-text
    {color: #525f7f;}
    .control_wrapper >>> .e-treeview .e-list-icon,.control_wrapper >>>  .e-treeview .e-list-img
    {display:none}
    .control_wrapper >>> .e-treeview:not(.e-fullrow-wrap) .e-list-item > .e-text-content
    {padding:0;background-color:transparent!important;color:#525f7f!important;}
    .control_wrapper >>> .e-treeview .e-ul:not(.e-list-parent)
    {padding:0;}
    .control_wrapper >>> .e-treeview > .e-ul
    {overflow-x:hidden;
    max-width:100%;}

    .control_wrapper >>> .e-treeview .e-ul .tree-column
    {width:33.33%;}

    .control_wrapper >>> .e-treeview .e-ul .type-20 .treeviewdiv
    {background-color: #ffffff; color:#525f7f!important;padding-left:5px;
    }
    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }
    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }
    .dynamic-delete-button:hover {
        color: #777;
    }
    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
    .element
    {display:block;width:100%; border-top-left-radius: 5px;border-top-right-radius:5px;overflow:hidden;}

    .element
    {
        padding:0;
        border-radius:0;border: 1px solid #eeeeee;
        margin-bottom:-1px;
    }
    .ant-table-body
    {width:100%}
    .manager
    {width:800px}
    .top-holder
    {width:100%;border:1px solid #eceff3; padding:15px   }
    .element-image
    {width:100%; border-bottom:1px solid #eceff3; padding-bottom:10px;margin-bottom:10px;}

    .element .handle
    {font-size:18px;padding:5px; padding-right:15px;
        display: inline-block;}
    .element span
    {width:100%; display:block; text-align:left; min-height:30px; font-size:12px; background-color: #fff;}
    .edit-button
    {float:right;  border-radius:0px;   border: 0;
        padding-top:4px;
    }
    .ant-select-selection--single
    {width:100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;}

</style>
